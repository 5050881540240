var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "visitor-list-view data-list-container",
        attrs: { id: "visitor-list-view" },
      },
      [
        _c(
          "vs-popup",
          {
            attrs: {
              title: "" + _vm.$t("vue.deleteAllVisitors"),
              active: _vm.removeAllVisitors,
            },
            on: {
              "update:active": function ($event) {
                _vm.removeAllVisitors = $event
              },
            },
          },
          [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("vue.confirmQuestion")) +
                  "\n      "
              ),
            ]),
            _c(
              "vs-button",
              {
                staticClass: "mr-2",
                attrs: { color: "#e8546f", type: "filled" },
                on: { click: _vm.deleteAll },
              },
              [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
            ),
            _c(
              "vs-button",
              {
                attrs: { type: "border", color: "#e8546f" },
                on: {
                  click: function ($event) {
                    _vm.removeAllVisitors = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
            ),
          ],
          1
        ),
        _c(
          "vs-popup",
          {
            staticClass: "visitors-vega-popup",
            attrs: {
              title: _vm.$t("vega.popup.connectedVegaPopups"),
              active: _vm.isSelectVegaPopupWindow,
            },
            on: {
              "update:active": function ($event) {
                _vm.isSelectVegaPopupWindow = $event
              },
            },
          },
          [
            _c("p", { staticClass: "mb-2 visitors-vega-popup__heading" }, [
              _vm._v(_vm._s(_vm.$t("vega.popup.selectVegaPopupToDisplay"))),
            ]),
            _c(
              "ul",
              { staticClass: "visitors-vega-popup__list" },
              _vm._l(_vm.selectionVegaPopupList, function (item) {
                return _c(
                  "li",
                  {
                    key: item.id,
                    staticClass: "visitors-vega-popup__list__item",
                    on: {
                      click: function ($event) {
                        return _vm.triggerSelectedVegaPopup(item)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                )
              }),
              0
            ),
          ]
        ),
        _c(
          "vs-prompt",
          {
            attrs: {
              title: _vm.$t("modals.deleteVisitor"),
              color: "danger",
              "cancel-text": _vm.$t("vue.cancel"),
              "accept-text": _vm.$t("vue.delete"),
              active: _vm.removeVisitor,
            },
            on: {
              cancel: _vm.onCloseModal,
              accept: function () {
                return _vm.deleteData(_vm.selectedVisitorId)
              },
              close: _vm.onCloseModal,
              "update:active": function ($event) {
                _vm.removeVisitor = $event
              },
            },
          },
          [
            _c("div", { staticClass: "con-exemple-prompt" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("modals.deleteVisitorQuestion")) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _c(
          "vs-prompt",
          {
            attrs: {
              "cancel-text": _vm.$t("vue.cancel"),
              "accept-text": _vm.$t("vue.save"),
              title: _vm.$t("vue.updateMobile"),
              active: _vm.mobilePrompt,
              "is-valid": _vm.validateForm,
            },
            on: {
              accept: _vm.updateMobile,
              "update:active": function ($event) {
                _vm.mobilePrompt = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "con-exemple-prompt" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|validatePhoneForRegion|validateMobile",
                      expression:
                        "'required|validatePhoneForRegion|validateMobile'",
                    },
                  ],
                  staticClass: "mt-3 w-full",
                  attrs: {
                    placeholder: _vm.$t("inputs.mobile"),
                    "data-vv-validate-on": "blur",
                    name: _vm.$t("inputs.mobile"),
                  },
                  model: {
                    value: _vm.mobile,
                    callback: function ($$v) {
                      _vm.mobile = $$v
                    },
                    expression: "mobile",
                  },
                }),
                _c("div", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first(_vm.$t("inputs.mobile")))),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "visitors-container" },
          [
            _c(
              "div",
              { class: !_vm.HAS_ACCESS ? "blur-background" : "" },
              [
                _c("link", {
                  attrs: {
                    rel: "stylesheet",
                    type: "text/css",
                    media: "all",
                    href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css",
                  },
                }),
                _c("visitor", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showVisitor,
                      expression: "showVisitor",
                    },
                  ],
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.checkAddonCompanyClearNames(_vm.companyInfo) &&
                          _vm.b2bEnabled &&
                          false,
                        expression:
                          "!checkAddonCompanyClearNames(companyInfo) && b2bEnabled && false",
                      },
                    ],
                    staticClass: "w-full mb-5",
                  },
                  [
                    _c(
                      "vs-alert",
                      {
                        staticClass: "addon-cc-message",
                        attrs: {
                          active: _vm.showAddonCompanyClearNameMessage,
                          closable: "",
                          "close-icon": "close",
                        },
                        on: {
                          "update:active": function ($event) {
                            _vm.showAddonCompanyClearNameMessage = $event
                          },
                        },
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("addons.companyClearMessage")
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.activeUserInfo.role === "admin" && !_vm.showVisitor
                  ? _c("div", { staticClass: "ml-6 mb-5 widget-filter" }, [
                      _c(
                        "div",
                        { staticClass: "widget-filter-select" },
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.widgetList,
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "preserve-search": true,
                              selectLabel: "",
                              selectedLabel: "",
                              deselectLabel: "",
                              placeholder: _vm.$t("vue.selectDialog"),
                              label: "name",
                              "track-by": "name",
                              "preselect-first": false,
                            },
                            on: { input: _vm.updateSelectedWidgets },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (ref) {
                                    var values = ref.values
                                    var isOpen = ref.isOpen
                                    return [
                                      values.length > 5
                                        ? _c(
                                            "vs-chip",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !isOpen,
                                                  expression: "!isOpen",
                                                },
                                              ],
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "vue.dialogsSelected"
                                                    )
                                                  )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            _vm._l(
                                              values,
                                              function (widget, index) {
                                                return _c(
                                                  "vs-chip",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !isOpen,
                                                        expression: "!isOpen",
                                                      },
                                                    ],
                                                    key: index,
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v(_vm._s(widget.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2878086167
                            ),
                            model: {
                              value: _vm.widgets,
                              callback: function ($$v) {
                                _vm.widgets = $$v
                              },
                              expression: "widgets",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "widget-filter-button" },
                        [
                          _c("vs-button", {
                            staticClass: "widget-filter-button--btn",
                            attrs: {
                              color: "primary",
                              type: "filled",
                              size: "medium",
                              "icon-pack": "feather",
                              icon: "icon-filter",
                              disabled: _vm.isWidgetSearchDisabled,
                            },
                            on: { click: _vm.updateWidgetFilter },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "vs-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showVisitor,
                        expression: "!showVisitor",
                      },
                    ],
                    key: _vm.tableKey,
                    ref: "table",
                    attrs: {
                      multiple: _vm.activeUserInfo.role === "admin",
                      pagination: "",
                      "max-items": _vm.itemsPerPage,
                      search: "",
                      data: _vm.visitorList,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "tbody",
                              _vm._l(data, function (tr, indextr) {
                                return _c(
                                  "vs-tr",
                                  { key: indextr, attrs: { data: tr } },
                                  [
                                    _c("vs-td", [
                                      _c("div", {
                                        staticClass:
                                          "visitor-list-view--online-indicator",
                                        class: {
                                          "visitor-list-view--online-indicator--offline":
                                            !tr.online,
                                        },
                                        attrs: { "data-id": tr.id },
                                      }),
                                    ]),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "visitor-list-view--column",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "visitor-list-view--column--multiple",
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "vx-tooltip",
                                                  {
                                                    staticClass:
                                                      "visitor-country",
                                                    attrs: {
                                                      text: _vm.countryName(
                                                        tr.country
                                                      ),
                                                      position: "top",
                                                    },
                                                  },
                                                  [
                                                    tr.country
                                                      ? _c("img", {
                                                          attrs: {
                                                            alt: _vm.countryName(
                                                              tr.country
                                                            ),
                                                            src: _vm.countryImage(
                                                              tr.country
                                                            ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("div", [
                                              _vm._v(_vm._s(tr.city)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    !_vm.b2bEnabled
                                      ? _c(
                                          "vs-td",
                                          {
                                            staticClass:
                                              "visitor-list-view--column about",
                                          },
                                          [
                                            tr.b2b
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-name font-medium visitor-list-view--wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          !_vm.HAS_B2B_ACCESS
                                                            ? _vm.$t(
                                                                "vue.businessConnection"
                                                              )
                                                            : tr.companyName
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-name font-medium visitor-list-view--wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "vue.privateConnection"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.b2bEnabled
                                      ? _c(
                                          "vs-td",
                                          {
                                            staticClass:
                                              "visitor-list-view--column about",
                                          },
                                          [
                                            tr.b2b
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-name font-medium visitor-list-view--wrap",
                                                  },
                                                  [
                                                    _vm.checkAddonCompanyClearNames(
                                                      _vm.companyInfo
                                                    )
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                !_vm.HAS_B2B_ACCESS
                                                                  ? _vm.$t(
                                                                      "vue.businessConnection"
                                                                    )
                                                                  : tr.companyName
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ])
                                                      : _vm.companyInfo &&
                                                        _vm.companyInfo
                                                          .paymentStatus ===
                                                          "trial"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class: {
                                                              blurtext:
                                                                _vm.showInfo(
                                                                  tr.itemNumber
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "shuffle"
                                                                  )(
                                                                    _vm.showInfo(
                                                                      tr.itemNumber
                                                                    )
                                                                      ? tr.companyName
                                                                      : tr.companyName
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm.companyInfo &&
                                                        _vm.companyInfo
                                                          .paymentStatus ===
                                                          "expired"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "blurtext",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "shuffle"
                                                                )(
                                                                  tr.companyName
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              tr.companyName
                                                            )
                                                          ),
                                                        ]),
                                                  ]
                                                )
                                              : _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-name font-medium visitor-list-view--wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "vue.privateConnection"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "visitor-list-view--column visitor-list-view--wrap",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tr.widgetName) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "visitor-list-view--column",
                                      },
                                      [
                                        _c("VisitorScoring", {
                                          attrs: {
                                            score: tr.score,
                                            online: tr.online,
                                            isVegaWidgetEnabled:
                                              tr.isVegaWidgetEnabled,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "visitor-list-view--column",
                                      },
                                      [
                                        _c("VisitorStatus", {
                                          key: tr.id,
                                          attrs: {
                                            score: tr.score,
                                            online: tr.online,
                                            isVegaWidgetEnabled:
                                              tr.isVegaWidgetEnabled,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-td",
                                      {
                                        staticClass:
                                          "whitespace-no-wrap visitor-list-view--column",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "visitor-list-view--action",
                                          },
                                          [
                                            _c("VisitorAction", {
                                              attrs: {
                                                online: tr.online,
                                                isCallStarted: tr.isCallStarted,
                                                isVegaActivated:
                                                  _vm.isVegaActivated,
                                                isVegaWidgetEnabled:
                                                  tr.isVegaWidgetEnabled,
                                              },
                                              on: {
                                                "initiate-call": function (
                                                  $event
                                                ) {
                                                  return _vm.agentInitiatesCall(
                                                    tr.id
                                                  )
                                                },
                                              },
                                            }),
                                            _c("ShowVegaPopup", {
                                              attrs: {
                                                online: tr.online,
                                                isCallStarted: tr.isCallStarted,
                                                isVegaActivated:
                                                  _vm.isVegaActivated,
                                                isVegaWidgetEnabled:
                                                  _vm.checkIsVegaWidgetEnabled(
                                                    tr.dialogId
                                                  ),
                                              },
                                              on: {
                                                "show-vega-popup": function (
                                                  $event
                                                ) {
                                                  return _vm.onShowVegaPopup(
                                                    tr.id
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "visitor-list-view--action visitor-list-view--info",
                                                class: {
                                                  "visitor-list-view--info--disabled":
                                                    tr.b2b &&
                                                    !_vm.HAS_B2B_ACCESS,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.viewData(
                                                      tr.id,
                                                      tr.b2b,
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("vs-icon", {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                  attrs: {
                                                    icon: "icon-info",
                                                    "icon-pack": "feather",
                                                    size: "small",
                                                    color:
                                                      tr.b2b &&
                                                      !_vm.HAS_B2B_ACCESS
                                                        ? "rgba(98, 98, 98, 0.4)"
                                                        : "rgb(98, 98, 98)",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "visitor-list-view--action visitor-list-view--delete",
                                                class: {
                                                  "visitor-list-view--delete--disabled":
                                                    !_vm.checkAddonCompanyClearNames(
                                                      _vm.companyInfo
                                                    ) &&
                                                    _vm.b2bEnabled &&
                                                    _vm.showInfo(tr.itemNumber),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.deleteVisitor(
                                                      tr.id,
                                                      tr.itemNumber
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("vs-icon", {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                  attrs: {
                                                    icon: "icon-trash",
                                                    size: "small",
                                                    "icon-pack": "feather",
                                                    color:
                                                      !_vm.checkAddonCompanyClearNames(
                                                        _vm.companyInfo
                                                      ) &&
                                                      _vm.b2bEnabled &&
                                                      _vm.showInfo(
                                                        tr.itemNumber
                                                      )
                                                        ? "rgba(240, 85, 6, 0.4)"
                                                        : "rgb(240, 85, 6)",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column items-start flex-grow justify-center",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "btn-add-new mb-4 rounded-lg cursor-pointer flex w-full flex-row items-center justify-start font-medium text-base",
                            class: !_vm.HAS_B2B_ACCESS ? "b2b-disabled" : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center items-center mr-4",
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "BriefcaseIcon",
                                    svgClasses: "h-4 w-4",
                                  },
                                }),
                                _c(
                                  "label",
                                  { staticClass: "ml-2 mr-2 heading-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("vue.businessVisitors"))
                                    ),
                                  ]
                                ),
                                _vm.HAS_B2B_ACCESS
                                  ? _c(
                                      "vs-switch",
                                      {
                                        attrs: {
                                          color: "#275D73",
                                          disabled: !_vm.HAS_B2B_ACCESS,
                                        },
                                        model: {
                                          value: _vm.b2bEnabled,
                                          callback: function ($$v) {
                                            _vm.b2bEnabled = $$v
                                          },
                                          expression: "b2bEnabled",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { attrs: { slot: "on" }, slot: "on" },
                                          [_vm._v(_vm._s(_vm.$t("vue.on")))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "off" },
                                            slot: "off",
                                          },
                                          [_vm._v(_vm._s(_vm.$t("vue.off")))]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.HAS_B2B_ACCESS
                                  ? _c(
                                      "vx-tooltip",
                                      {
                                        staticStyle: { height: "17px" },
                                        attrs: {
                                          text: _vm.$t(
                                            "upgrade-messages.locked-feature"
                                          ),
                                          position: "top",
                                        },
                                      },
                                      [_c("small-lock-icon")],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "vx-tooltip",
                                  {
                                    staticClass: "flex ml-1",
                                    staticStyle: { width: "17px" },
                                    attrs: {
                                      text: _vm.$t("info.businessCustomers"),
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { display: "inline-block" },
                                      attrs: {
                                        width: "17",
                                        height: "17",
                                        src: _vm.infoImg,
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center items-center mr-4",
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "RadioIcon",
                                    svgClasses: "h-4 w-4",
                                  },
                                }),
                                _c(
                                  "label",
                                  { staticClass: "ml-2 mr-2 heading-label" },
                                  [_vm._v(_vm._s(_vm.$t("vue.onlineVisitors")))]
                                ),
                                _c(
                                  "vs-switch",
                                  {
                                    attrs: { color: "#275D73" },
                                    model: {
                                      value: _vm.showOnlineVisitors,
                                      callback: function ($$v) {
                                        _vm.showOnlineVisitors = $$v
                                      },
                                      expression: "showOnlineVisitors",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { slot: "on" }, slot: "on" },
                                      [_vm._v(_vm._s(_vm.$t("vue.on")))]
                                    ),
                                    _c(
                                      "span",
                                      { attrs: { slot: "off" }, slot: "off" },
                                      [_vm._v(_vm._s(_vm.$t("vue.off")))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "vx-tooltip",
                                  {
                                    staticClass: "flex ml-1",
                                    staticStyle: { height: "17px" },
                                    attrs: {
                                      text: _vm.$t(
                                        "info.currentWebsiteVisitors"
                                      ),
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { display: "inline-block" },
                                      attrs: {
                                        width: "17",
                                        height: "17",
                                        src: _vm.infoImg,
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center items-center mr-4",
                              },
                              [
                                _c(
                                  "vx-tooltip",
                                  {
                                    staticClass: "inline-flex",
                                    attrs: {
                                      text: _vm.$t("calendar.name"),
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c("vs-button", {
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        size: "medium",
                                        "icon-pack": "feather",
                                        icon: "icon-calendar",
                                      },
                                      on: { click: _vm.showDateRange },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "date-range-picker",
                                  {
                                    ref: "picker",
                                    staticClass: "ml-2",
                                    attrs: {
                                      opens: "center",
                                      localeData: _vm.localeData,
                                      maxDate: _vm.maxDate,
                                      showWeekNumbers: false,
                                      showDropdowns: true,
                                      ranges: false,
                                      autoApply: true,
                                      linkedCalendars: true,
                                    },
                                    on: { update: _vm.applyFilter },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "input",
                                        fn: function (picker) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("date")(
                                                    picker.startDate
                                                  )
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm._f("date")(picker.endDate)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "footer",
                                        fn: function (data) {
                                          return _c(
                                            "div",
                                            { staticClass: "slot" },
                                            [
                                              _c("div", [
                                                _c(
                                                  "b",
                                                  { staticClass: "text-black" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("vue.dateRange")
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      data.rangeText &&
                                                        data.rangeText.trim()
                                                          .length > 1
                                                        ? data.rangeText
                                                        : ""
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "auto",
                                                  },
                                                },
                                                [
                                                  !data.in_selection
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "btn btn-primary btn-sm",
                                                          on: {
                                                            click:
                                                              data.clickApply,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Choose current"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.dateRange,
                                      callback: function ($$v) {
                                        _vm.dateRange = $$v
                                      },
                                      expression: "dateRange",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "slot",
                                        attrs: { slot: "header" },
                                        slot: "header",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("vue.chooseDateRange")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.dateRange.startDate || _vm.dateRange.endDate
                                  ? _c("vs-button", {
                                      staticClass: "ml-2",
                                      attrs: {
                                        "icon-pack": "feather",
                                        icon: "icon-x",
                                        type: "border",
                                      },
                                      on: { click: _vm.clearDateFilter },
                                    })
                                  : _vm._e(),
                                _c(
                                  "vx-tooltip",
                                  {
                                    staticClass: "ml-1",
                                    staticStyle: { height: "17px" },
                                    attrs: {
                                      text: _vm.$t("info.date"),
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { display: "inline-block" },
                                      attrs: {
                                        width: "17",
                                        height: "17",
                                        src: _vm.infoImg,
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-center items-center mr-4",
                              },
                              [
                                _c(
                                  "vs-dropdown",
                                  {
                                    staticClass:
                                      "cursor-pointer mr-4 items-per-page-handler",
                                    attrs: { "vs-trigger-click": "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                                        staticStyle: { "border-radius": "5px" },
                                      },
                                      [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.firstRecordNumber) +
                                              " - " +
                                              _vm._s(_vm.lastRecordNumber) +
                                              " " +
                                              _vm._s(_vm.$t("vue.of")) +
                                              "\n                      " +
                                              _vm._s(_vm.queriedItems) +
                                              "\n                    "
                                          ),
                                        ]),
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "ChevronDownIcon",
                                            svgClasses: "h-4 w-4",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-dropdown-menu",
                                      [
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateItemsPerPage(4)
                                              },
                                            },
                                          },
                                          [_c("span", [_vm._v("4")])]
                                        ),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateItemsPerPage(
                                                  10
                                                )
                                              },
                                            },
                                          },
                                          [_c("span", [_vm._v("10")])]
                                        ),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateItemsPerPage(
                                                  15
                                                )
                                              },
                                            },
                                          },
                                          [_c("span", [_vm._v("15")])]
                                        ),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateItemsPerPage(
                                                  20
                                                )
                                              },
                                            },
                                          },
                                          [_c("span", [_vm._v("20")])]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.online")))]),
                        _c("vs-th", { attrs: { "sort-key": "country" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.location"))),
                        ]),
                        _c("vs-th", { attrs: { "sort-key": "companyName" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.connection"))),
                        ]),
                        _c("vs-th", { attrs: { "sort-key": "dialogId" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.widgetName"))),
                        ]),
                        _c("vs-th", { attrs: { "sort-key": "scoring" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.buyingIntent"))),
                        ]),
                        _c("vs-th", { attrs: { "sort-key": "scoring" } }, [
                          _vm._v(_vm._s(_vm.$t("vue.status"))),
                        ]),
                        _c("vs-th", [_vm._v(_vm._s(_vm.$t("vue.action")))]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            !_vm.HAS_ACCESS ? _c("visitors-message") : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }